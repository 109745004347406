import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '../mui/button';
import { makeStyles } from '@material-ui/core/styles';
import { sendMail } from '../../services/mail';
import { Controller, useForm } from 'react-hook-form';

const useDialogStyles = makeStyles({
  root: {
    '& [class*="MuiPaper"]': {
      maxWidth: 630,
    },
  },
});

const useFormStyles = makeStyles((theme) => ({
  root: {
    '& [class*="MuiFormControl-root"]': {
      width: '100%',
    },
    '& [class*="MuiInputBase-root"]': {
      marginBottom: '0 !important',
    },
  },
  error: {
    lineHeight: '15px',
    color: theme.palette.error.main,
  },
  buttonProgress: {
    color: theme.palette.primary.light,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    display: 'inline-block',
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

interface PackagesCalculatorSubmitterProps {
  open: boolean;
  currentWebsites: number;
  adjustedPackage: MarvelAndSnapPackage;
  handleClose(): any;
}

const PackagesCalculatorSubmitter: React.FC<PackagesCalculatorSubmitterProps> = ({
  open,
  currentWebsites,
  adjustedPackage,
  handleClose,
}) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dialogStyles = useDialogStyles();
  const formStyles = useFormStyles();

  const onSubmit = (values: any) => {
    const from = values.email;
    const subject = 'New submission from Packages page';

    const bodyParts: string[] = [
      `<p><strong>From:</strong> ${values.firstName} ${values.lastName} <${values.email}></p>`,
    ];

    if (values.comments.length) {
      bodyParts.push(`<p><strong>Comments:</strong> ${values.comments}</p>`);
    }

    bodyParts.push('<ul>');

    bodyParts.push(
      `<li><strong>currentWebsites:</strong> ${currentWebsites}</li>`
    );

    for (const key in adjustedPackage) {
      bodyParts.push(
        `<li><strong>${key}:</strong> ${adjustedPackage[key]}</li>`
      );
    }

    bodyParts.push('</ul>');

    const message = bodyParts.join('');

    setSubmitting(true);

    sendMail('info@marvelandsnap.com', from, subject, message)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitFailure(true);
        setSubmitting(false);
      });
  };

  const handleExited = () => {
    setSubmitSuccess(false);
    setSubmitFailure(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  let dialogContent: any;

  if (submitSuccess) {
    dialogContent = (
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="subtitle1"
          component="p"
          color="textSecondary"
          gutterBottom={true}
        >
          Thank you for being awesome!
        </Typography>
        <Typography variant="body2" gutterBottom={true}>
          We will look over your message and get back in touch with you very
          shortly.
        </Typography>
        <Typography variant="body2">Have a great day!</Typography>
      </div>
    );
  } else if (submitFailure) {
    dialogContent = (
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="subtitle1"
          component="p"
          color="textSecondary"
          gutterBottom={true}
        >
          Something went wrong!
        </Typography>
        <Typography variant="body2">
          Your request could not be completed. Please contact{' '}
          <Link href="mailto:support@marvelandsnap.com">
            support@marvelandsnap.com
          </Link>{' '}
          for assistance.
        </Typography>
      </div>
    );
  } else {
    dialogContent = (
      <React.Fragment>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body2" gutterBottom={true}>
            If you’re interested in this web operations plan, we’re more than
            happy to continue the conversation with you.
          </Typography>
          <Typography variant="body2" gutterBottom={true}>
            Fill out the form below and someone from our team will get back to
            you shortly.
          </Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    id="firstName"
                    label="first name"
                    variant="outlined"
                    onChange={onChange}
                    fullWidth
                    inputRef={ref}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    id="lastName"
                    label="last name"
                    variant="outlined"
                    onChange={onChange}
                    fullWidth
                    inputRef={ref}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    id="email"
                    label="email"
                    variant="outlined"
                    onChange={onChange}
                    fullWidth
                    inputRef={ref}
                  />
                )}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="comments"
                render={({ field: { onChange, ref } }) => (
                  <TextField
                    id="comments"
                    label="comments"
                    variant="outlined"
                    onChange={onChange}
                    fullWidth
                    multiline={true}
                    rows={3}
                    inputRef={ref}
                  />
                )}
                rules={{ required: true }}
              />

              {errors.firstName || errors.lastName || errors.comments ? (
                <Typography
                  style={{ marginBottom: 20 }}
                  className={formStyles.error}
                >
                  All fields are mandatory
                </Typography>
              ) : errors.email ? (
                <Typography
                  style={{ marginBottom: 20 }}
                  className={formStyles.error}
                >
                  Please enter a valid email address
                </Typography>
              ) : null}
            </Grid>
            <Grid item>
              <div className={formStyles.wrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  disabled={submitting}
                >
                  Submit
                </Button>
                {submitting && (
                  <CircularProgress
                    size={24}
                    className={formStyles.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </form>

        {/* <Formik
          initialValues={initialValues}
          validationSchema={SubmitterSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form className={formStyles.root} autoComplete="off">
              <Grid container spacing={3} justify="center">
                <Grid item xs={12} md={6}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    variant="outlined"
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    variant="outlined"
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="emailAddress"
                    name="emailAddress"
                    label="Email Address"
                    type="email"
                    value={values.emailAddress}
                    onChange={handleChange}
                    variant="outlined"
                    error={
                      errors.emailAddress && touched.emailAddress ? true : false
                    }
                    helperText={
                      errors.emailAddress && touched.emailAddress
                        ? errors.emailAddress
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="comments"
                    name="comments"
                    label="Comments"
                    value={values.comments}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={2}
                    error={errors.comments && touched.comments ? true : false}
                    helperText={
                      errors.comments && touched.comments ? errors.comments : ''
                    }
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik> */}
      </React.Fragment>
    );
  }

  return (
    <Dialog
      className={dialogStyles.root}
      open={open}
      onClose={handleClose}
      onExited={handleExited}
    >
      <DialogContent>
        <Box p={4}>{dialogContent}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default PackagesCalculatorSubmitter;
