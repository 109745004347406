import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Container,
  Grid,
  Slider,
  Typography,
} from '@material-ui/core';
import LightMode from '../global/light-mode';
import VerticalPadding from '../global/vertical-padding';

import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { Controller, useForm } from 'react-hook-form';

import checkmarkIcon from '../../images/svg/checkmark-icon.svg';

import PackagesCalculatorSummary from './packages-calculator-summary';
import PackagesCalculatorSubmitter from './packages-calculator-submitter';

const formTransition = '.3s ease';

const useFormStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
  },
  form: {
    '& [class*="MuiSlider-root"]': {
      marginLeft: 12,
      width: 'calc(100% - 24px)',
      '& [class*="MuiSlider-rail"], & [class*="MuiSlider-track"]': {
        borderRadius: 0,
        height: 3,
        '&:after': {
          content: '""',
          display: 'block',
          height: 3,
          position: 'absolute',
          top: 0,
          width: 11,
        },
      },
      '& [class*="MuiSlider-rail"]': {
        '&:after': {
          backgroundColor: theme.palette.primary.dark,
          left: '100%',
        },
      },
      '& [class*="MuiSlider-track"]': {
        backgroundColor: theme.palette.primary.dark,
        '&:after': {
          backgroundColor: theme.palette.primary.dark,
          right: '100%',
        },
      },
      '& [class*="MuiSlider-thumb"]': {
        borderRadius: 0,
        height: 25,
        marginTop: -12,
        marginLeft: -12,
        width: 25,
      },
    },
    '& input[type="number"]': {
      border: `2px solid ${fade(theme.palette.primary.main, 0.5)}`,
      '-moz-appearance': 'textfield',
      fontSize: 24,
      outline: 'none',
      padding: '.8em 1.2em',
      textAlign: 'center',
      transition: `border-color ${formTransition}`,
      width: '7em',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
      [theme.breakpoints.only('xs')]: {
        width: '4em',
      },
    },
  },
  field: {
    '&__label': {
      color: theme.palette.primary.dark,
      display: 'block',
      fontFamily: 'Visby CF',
      fontSize: 24,
      fontWeight: 700,
      marginBottom: 20,
      textTransform: 'lowercase',
    },
    '&:nth-child(n+2) &__label': {
      marginTop: 50,
    },
    '&__error': {
      color: theme.palette.error.main,
      fontWeight: 700,
      marginTop: theme.spacing(1),
    },
  },
  multi: {
    '& input': {
      display: 'none',
    },
    '& label': {
      cursor: 'pointer',
      display: 'block',
      fontSize: 24,
      outline: 'none',
      padding: '.8em 1.2em',
      '& small': {
        color: theme.palette.grey[600],
        display: 'block',
        fontSize: 18,
      },
    },
    '&--radio': {
      '& label': {
        border: `2px solid ${theme.palette.primary.main}`,
        textAlign: 'center',
        opacity: 0.5,
        transition: `opacity ${formTransition}`,
      },
      '& input:checked + label': {
        opacity: 1,
      },
    },
    '&--checkbox': {
      '& label': {
        border: `2px solid ${fade(theme.palette.primary.main, 0.5)}`,
        paddingRight: 72,
        position: 'relative',
        transition: `border-color ${formTransition}`,
      },
      '& input:checked + label': {
        borderColor: theme.palette.primary.main,
      },
    },
    '&__checkmark': {
      height: 'auto',
      float: 'right',
      position: 'absolute',
      right: '1.2em',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 24,
    },
  },
}));

const useFootnotesStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[800],
    display: 'grid',
    fontSize: 16,
    gap: '5px',
    gridTemplateAreas: '"label value"',
    gridAutoColumns: 'min-content 1fr',
    margin: 0,
    width: '100%',
    '& dt': {
      fontSize: 11,
      gridArea: '"label"',
    },
    '& dd': {
      gridArea: '"value"',
      margin: 0,
      '& a': {
        color: theme.palette.primary.dark,
        fontWeight: 500,
        textDecoration: 'none',
      },
    },
  },
}));

const needsHostingOptions = [{ value: 'Yes' }, { value: 'No' }];

const updateFrequencyOptions = [
  {
    value: 'A couple of times a month',
    requestsPerMonth: 2,
    hoursPerMonthWeight: 0.8,
  },
  {
    value: 'Once a week',
    requestsPerMonth: 5,
    hoursPerMonthWeight: 0.85,
  },
  {
    value: 'A few times a week',
    requestsPerMonth: 10,
    hoursPerMonthWeight: 0.9,
  },
  {
    value: 'At least once a day',
    requestsPerMonth: 25,
    hoursPerMonthWeight: 0.95,
  },
  {
    value: 'Several times a day',
    requestsPerMonth: 50,
    hoursPerMonthWeight: 1,
  },
];

const typicalUpdatesOptions = [
  {
    value: 'Text formatting',
    small: '(e.g., blog posts, news releases, page content updates)',
    hoursPerRequest: 0.08,
  },
  {
    value: 'Image optimization',
    small: '(e.g., resize/crop images, compress file size, etc.)',
    hoursPerRequest: 0.17,
  },
  {
    value: 'Layout changes',
    small: '(e.g., reorder sections, create new layouts, update forms)',
    hoursPerRequest: 0.25,
  },
  {
    value: 'Create new pages',
    small: '(e.g., campaign landing pages, new products, events, etc.)',
    hoursPerRequest: 0.5,
  },
  {
    value: 'Third-party integrations',
    small: '(e.g., HubSpot, Marketo, Salesforce, etc.)',
    hoursPerRequest: 0.75,
  },
  {
    value: 'Custom theme development',
    small: '(e.g., develop new templates, components, and/or plugins)',
    hoursPerRequest: 1,
  },
  {
    value: 'Creating new functionality',
    small:
      '(e.g., product calculator, e-commerce, resource library, customer portals, etc.)',
    hoursPerRequest: 2,
  },
  {
    value: 'Web best practices',
    small: '(e.g., pagespeed optimizations, AODA/WCAG review/updates, etc.)',
    hoursPerRequest: 3,
  },
];

const rollingHoursOptions = [{ value: 'Yes' }, { value: 'No' }];

const previewChangesOptions = [{ value: 'Yes' }, { value: 'No' }];

const backupsOptions = [{ value: 'Yes' }, { value: 'No' }];

const webTeamOptions = [{ value: 'Yes' }, { value: 'No' }];

const defaultValues = {
  currentWebsites: 1,
  needsHosting: needsHostingOptions[0].value,
  updateFrequency: updateFrequencyOptions[0].value,
  typicalUpdates: [
    typicalUpdatesOptions[0].value,
    typicalUpdatesOptions[1].value,
  ],
  rollingHours: rollingHoursOptions[1].value,
  previewChanges: previewChangesOptions[0].value,
  backups: backupsOptions[0].value,
  webTeam: webTeamOptions[1].value,
};

const packages = [
  {
    name: 'Hosting',
    billing: 'Annually',
    hoursPerMonth: 2,
    responseTime: '< 6 hours',
    weeklyUpdates: true,
    uptimeMonitoring: true,
    weeklyAnalytics: false,
    monthlyTimesheets: false,
    rollingHours: false,
    maximumRollingHours: false,
    managedHosting: true,
    costPerMonth: '$125',
    costPerYear: '$1,500',
  },
  {
    name: 'Small Team',
    billing: 'Monthly',
    hoursPerMonth: 10,
    responseTime: '< 4 hours',
    weeklyUpdates: true,
    uptimeMonitoring: true,
    weeklyAnalytics: true,
    monthlyTimesheets: true,
    rollingHours: true,
    maximumRollingHours: 50,
    managedHosting: true,
    costPerMonth: '$1,485',
    costPerYear: '$17,820',
  },
  {
    name: 'Business',
    billing: 'Monthly',
    hoursPerMonth: 20,
    responseTime: '< 2 hours',
    weeklyUpdates: true,
    uptimeMonitoring: true,
    weeklyAnalytics: true,
    monthlyTimesheets: true,
    rollingHours: true,
    maximumRollingHours: 100,
    managedHosting: true,
    costPerMonth: '$2,640',
    costPerYear: '$31,680',
  },
  {
    name: 'Enterprise',
    billing: 'Monthly',
    hoursPerMonth: 40,
    responseTime: 'Priority Support',
    weeklyUpdates: true,
    uptimeMonitoring: true,
    weeklyAnalytics: true,
    monthlyTimesheets: true,
    rollingHours: true,
    maximumRollingHours: 200,
    managedHosting: true,
    costPerMonth: '$4,400',
    costPerYear: '$52,800',
  },
];

const calculatePackages = (values) => {
  const updateFrequency = updateFrequencyOptions.filter(
    (option) => option.value === values.updateFrequency
  )[0];

  const requestsPerMonth = updateFrequency.requestsPerMonth; // * values.currentWebsites;

  let hoursPerRequest = 0;

  typicalUpdatesOptions.forEach((option) => {
    if (values.typicalUpdates.indexOf(option.value) > -1) {
      hoursPerRequest += option.hoursPerRequest;
    }
  });

  let hoursPerMonth = requestsPerMonth * hoursPerRequest;

  if (values.needsHosting === 'No') {
    hoursPerMonth += 1;
  }

  hoursPerMonth = hoursPerMonth * updateFrequency.hoursPerMonthWeight;

  let calculatedPackage = packages.find((p) => hoursPerMonth < p.hoursPerMonth);

  if (calculatedPackage === undefined) {
    const largestPackage = packages[packages.length - 1];

    if (hoursPerMonth >= largestPackage.hoursPerMonth) {
      calculatedPackage = largestPackage;
    } else {
      calculatedPackage = packages[0];
    }
  }

  let adjustedPackage = calculatedPackage;

  if (calculatedPackage.name === 'Startup' && values.rollingHours === 'Yes') {
    adjustedPackage = packages.filter((p) => p.name === 'Small Team')[0];
  }

  return { calculatedPackage, adjustedPackage };
};

const PackagesCalculator: React.FC = () => {
  const formStyles = useFormStyles();
  const footnotesStyles = useFootnotesStyles();

  const Checkmark: React.FC = () => (
    <img
      className={`${formStyles.multi}__checkmark`}
      src={checkmarkIcon}
      alt="Checked"
      width="24"
      height="19"
    />
  );

  const [{ calculatedPackage, adjustedPackage }, setPackages] = useState(
    calculatePackages(defaultValues)
  );

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
  });

  const values = watch();

  const Calculator = () => {
    React.useEffect(() => {
      setPackages((prevState) => {
        const { calculatedPackage, adjustedPackage } =
          calculatePackages(values);

        if (prevState.adjustedPackage.name === adjustedPackage.name) {
          return prevState;
        } else {
          return { calculatedPackage, adjustedPackage };
        }
      });
    }, [values]);
    return null;
  };

  const [submitterOpen, setSubmitterOpen] = useState(false);

  const onSubmit = () => {
    setSubmitterOpen(true);
  };

  const handleSubmitterClose = () => {
    setSubmitterOpen(false);
  };

  return (
    <React.Fragment>
      <LightMode>
        <VerticalPadding>
          <Container>
            <Typography
              variant="h2"
              component="h3"
              style={{ marginBottom: 60, textAlign: 'center' }}
            >
              Calculate Your Price
            </Typography>
            <Box className={formStyles.box}>
              <form
                className={formStyles.form}
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={9}>
                  <Grid item xs={12} lg={7} xl={8}>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="currentWebsites__label"
                    >
                      <label
                        id="currentWebsites__label"
                        className={`${formStyles.field}__label`}
                        htmlFor="currentWebsites"
                      >
                        How many websites do you need us to manage?
                      </label>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs>
                          <Controller
                            control={control}
                            name="currentWebsites"
                            render={() => (
                              <Slider
                                min={1}
                                max={5}
                                value={Number(values.currentWebsites)}
                                onChange={(event, value) => {
                                  setValue('currentWebsites', Number(value));
                                }}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </Grid>
                        <Grid item>
                          <Controller
                            control={control}
                            name="currentWebsites"
                            render={({ field: { onChange, ref } }) => (
                              <input
                                id="currentWebsites"
                                name="currentWebsites"
                                type="number"
                                min={1}
                                max={5}
                                ref={ref}
                                onChange={onChange}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </Grid>
                      </Grid>
                      {errors.currentWebsites && (
                        <div className={`${formStyles.field}__error`}>
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="needsHosting__label"
                    >
                      <label
                        id="needsHosting__label"
                        className={`${formStyles.field}__label`}
                      >
                        Will Marvel{' '}
                        <span style={{ fontSize: '0.8em' }}>&amp;</span> Snap be
                        hosting your website(s)?
                      </label>
                      <Grid container spacing={3}>
                        {needsHostingOptions.map((option, index) => (
                          <Grid key={index} item xs={6} lg={4}>
                            <div
                              className={`${formStyles.multi} ${formStyles.multi}--radio`}
                            >
                              <input
                                id={`needsHosting__${index}`}
                                type="radio"
                                value={option.value}
                                {...register('needsHosting', {
                                  required: true,
                                })}
                              />
                              <label htmlFor={`needsHosting__${index}`}>
                                {option.value}
                              </label>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      {errors.needsHosting && (
                        <div className={`${formStyles.field}__error`}>
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="updateFrequency__label"
                    >
                      <label
                        id="updateFrequency__label"
                        className={`${formStyles.field}__label`}
                      >
                        On average, how often do you make updates to your
                        website?
                      </label>
                      <Grid container spacing={1}>
                        {updateFrequencyOptions.map((option, index) => (
                          <Grid item key={index} xs={12}>
                            <div
                              className={`${formStyles.multi} ${formStyles.multi}--checkbox`}
                            >
                              <input
                                id={`updateFrequency__${index}`}
                                type="radio"
                                value={option.value}
                                {...register('updateFrequency', {
                                  required: true,
                                })}
                              />
                              <label htmlFor={`updateFrequency__${index}`}>
                                {option.value}
                                {values.updateFrequency === option.value && (
                                  <Checkmark />
                                )}
                              </label>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      {errors.updateFrequency && (
                        <div className={`${formStyles.field}__error`}>
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="typicalUpdates__label"
                    >
                      <label
                        id="typicalUpdates__label"
                        className={`${formStyles.field}__label`}
                      >
                        What is a typical update you would do to your website?
                      </label>
                      <Grid container spacing={1}>
                        {typicalUpdatesOptions.map((option, index) => (
                          <Grid item key={index} xs={12}>
                            <div
                              className={`${formStyles.multi} ${formStyles.multi}--checkbox`}
                            >
                              <input
                                id={`typicalUpdates__${index}`}
                                type="checkbox"
                                value={option.value}
                                {...register('typicalUpdates', {
                                  required: true,
                                })}
                              />
                              <label htmlFor={`typicalUpdates__${index}`}>
                                {option.value}
                                <small>{option.small}</small>
                                {values.typicalUpdates.indexOf(option.value) !==
                                  -1 && <Checkmark />}
                              </label>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      {errors.typicalUpdates && (
                        <div className={`${formStyles.field}__error`}>
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="rollingHours__label"
                    >
                      <Collapse in={calculatedPackage.name === 'Startup'}>
                        <label
                          id="rollingHours__label"
                          className={`${formStyles.field}__label`}
                        >
                          If you have unused hours in a given month, would you
                          like those to be rolled over to the next month?
                        </label>
                        <Grid container spacing={3}>
                          {rollingHoursOptions.map((option, index) => (
                            <Grid item key={index} xs={6} lg={4}>
                              <div
                                className={`${formStyles.multi} ${formStyles.multi}--radio`}
                              >
                                <input
                                  id={`rollingHours__${index}`}
                                  type="radio"
                                  value={option.value}
                                  {...register('rollingHours', {
                                    required: true,
                                  })}
                                />
                                <label htmlFor={`rollingHours__${index}`}>
                                  {option.value}
                                </label>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                        {errors.rollingHours && (
                          <div className={`${formStyles.field}__error`}>
                            This field is required.
                          </div>
                        )}
                      </Collapse>
                    </div>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="previewChanges__label"
                    >
                      <label
                        id="previewChanges__label"
                        className={`${formStyles.field}__label`}
                      >
                        Would you like to see your changes before they get
                        pushed to your live site?
                      </label>
                      <Grid container spacing={3}>
                        {previewChangesOptions.map((option, index) => (
                          <Grid item key={index} xs={6} lg={4}>
                            <div
                              className={`${formStyles.multi} ${formStyles.multi}--radio`}
                            >
                              <input
                                id={`previewChanges__${index}`}
                                type="radio"
                                value={option.value}
                                {...register('previewChanges', {
                                  required: true,
                                })}
                              />
                              <label htmlFor={`previewChanges__${index}`}>
                                {option.value}
                              </label>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      {errors.previewChanges && (
                        <div className={`${formStyles.field}__error`}>
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="backups__label"
                    >
                      <label
                        id="backups__label"
                        className={`${formStyles.field}__label`}
                      >
                        Is it important to you that you have a backup of your
                        site?
                      </label>
                      <Grid container spacing={3}>
                        {backupsOptions.map((option, index) => (
                          <Grid item key={index} xs={6} lg={4}>
                            <div
                              className={`${formStyles.multi} ${formStyles.multi}--radio`}
                            >
                              <input
                                id={`backups__${index}`}
                                type="radio"
                                value={option.value}
                                {...register('backups', {
                                  required: true,
                                })}
                              />
                              <label htmlFor={`backups__${index}`}>
                                {option.value}
                              </label>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      {errors.backups && (
                        <div className={`${formStyles.field}__error`}>
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div
                      className={formStyles.field}
                      role="group"
                      aria-labelledby="webTeam__label"
                    >
                      <label
                        id="webTeam__label"
                        className={`${formStyles.field}__label`}
                      >
                        Do you currently have an in-house web team?
                      </label>
                      <Grid container spacing={3}>
                        {webTeamOptions.map((option, index) => (
                          <Grid item key={index} xs={6} lg={4}>
                            <div
                              className={`${formStyles.multi} ${formStyles.multi}--radio`}
                            >
                              <input
                                id={`webTeam__${index}`}
                                type="radio"
                                value={option.value}
                                {...register('webTeam', {
                                  required: true,
                                })}
                              />
                              <label htmlFor={`webTeam__${index}`}>
                                {option.value}
                              </label>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      {errors.webTeam && (
                        <div className={`${formStyles.field}__error`}>
                          This field is required.
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={5} xl={4}>
                    <PackagesCalculatorSummary
                      currentWebsites={values.currentWebsites}
                      adjustedPackage={adjustedPackage}
                    />
                  </Grid>
                </Grid>
                <Calculator />
                <PackagesCalculatorSubmitter
                  open={submitterOpen}
                  currentWebsites={values.currentWebsites}
                  adjustedPackage={adjustedPackage}
                  handleClose={handleSubmitterClose}
                />
              </form>
            </Box>
            <Box mt={4} mx={3}>
              <dl className={footnotesStyles.root}>
                <dt id="footnote-1">1</dt>
                <dd>
                  The time required to update WordPress core/plugins will be
                  deducted from your monthly hours if your website is not being
                  hosted on Pantheon.
                </dd>
                <dt id="footnote-2">2</dt>
                <dd>
                  Includes one Pantheon Basic plan. Additional hosting fees will
                  be applied if you exceed{' '}
                  <a
                    href="https://pantheon.io/docs/overages-site-plan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pantheon’s maximum allowed monthly visits
                  </a>
                  , have more than one site, or choose a different Pantheon
                  Plan.
                </dd>
              </dl>
            </Box>
          </Container>
        </VerticalPadding>
      </LightMode>
    </React.Fragment>
  );
};

export default PackagesCalculator;
