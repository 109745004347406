import React from 'react';
import Layout from '../components/global/layout';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import Button from '../components/mui/button';
import PageBanner from '../components/layouts/page-banner';
import VerticalPadding from '../components/global/vertical-padding';
import PackagesCalculator from '../components/layouts/packages-calculator';
import CallToAction from '../components/layouts/call-to-action';
import SEO from '../components/seo';

const Packages: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Choose Your Web Development, Hosting, and Management Package"
        description="Worry-free web operations packages that match your development, hosting, and management requirements."
      />
      <PageBanner
        heading="Match Your Requirements With the Right Package"
        imageSrc="packages-banner.jpg"
      >
        Match Your <Hidden smUp>Needs</Hidden>
        <Hidden xsUp> and </Hidden>
        <Hidden xsDown>Requirements</Hidden> With the Right Package
      </PageBanner>
      <Box bgcolor="primary.main">
        <VerticalPadding>
          <Container>
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                md={11}
                lg={10}
                xl={9}
                style={{ textAlign: 'center' }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  style={{
                    color: 'white',
                    fontFamily: 'Visby CF',
                    textTransform: 'unset',
                  }}
                >
                  If you&apos;re not sure which of our monthly web operations
                  packages would be the right fit for you, fill out the
                  information below and get an instant recommendation.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </Box>
      <PackagesCalculator />
      <CallToAction imageSrc="call-to-action-laptop-chair.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '9em' }}
        >
          Get Worry-Free Web Operations
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Develop, launch, manage, and iterate digital marketing assets faster.
          And bring advanced digital experiences to your audience more quickly
          and efficiently.
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          to="/contact/"
        >
          Let&apos;s talk
        </Button>
      </CallToAction>
    </Layout>
  );
};

export default Packages;
