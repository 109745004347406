import React, { useContext } from 'react';
import { Divider, Typography } from '@material-ui/core';
import Button from '../mui/button';

import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ThemeContext from '../../contexts/theme-context';

import checkmarkIcon from '../../images/svg/checkmark-icon.svg';
import Logo from '../svg/logo';

const useSummaryStyles = makeStyles((theme) => ({
  root: (appBarVisible) => ({
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    textAlign: 'center',
    top: theme.spacing(8) + (appBarVisible ? 150 : 0),
    transition: `top ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`,
    [theme.breakpoints.up('lg')]: {
      position: 'sticky',
    },
    '& [class*="MuiDivider"]': {
      backgroundColor: fade(theme.palette.neutral.dark, 0.3),
      height: 2,
      margin: `${theme.spacing(3)}px 0`,
    },
    '& [class*="MuiButton-root"]': {
      margin: `${theme.spacing(3)}px 0`,
    },
  }),
  logo: {
    maxWidth: 223,
  },
  heading: {
    fontFamily: 'Visby CF',
    textTransform: 'none',
  },
  costPerMonth: {
    color: theme.palette.grey[600],
    marginTop: theme.spacing(2),
    '& strong': {
      color: theme.palette.violet.dark,
    },
  },
  additionalCostPerMonth: {
    color: theme.palette.neutral.dark,
    fontSize: 16,
    marginTop: theme.spacing(1),
    '& strong': {
      color: theme.palette.violet.dark,
    },
  },
  features: {
    display: 'grid',
    fontSize: 14,
    fontWeight: 500,
    gap: '15px 0',
    gridTemplateAreas: '"label value"',
    gridAutoColumns: '1fr 80px',
    marginTop: theme.spacing(3),
    width: '100%',
    '&__label': {
      color: theme.palette.violet.dark,
      gridArea: '"label"',
      textAlign: 'left',
      '& sup': {
        color: theme.palette.grey[600],
        fontWeight: 400,
        fontSize: 12,
        marginLeft: 2,
        '& a': {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
    },
    '&__value': {
      alignItems: 'center',
      color: theme.palette.grey[600],
      display: 'flex',
      gridArea: '"value"',
      justifyContent: 'center',
      margin: 0,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    '&__checkmark': {
      height: 'auto',
      width: 24,
    },
  },
  costPerYear: {
    color: theme.palette.violet.dark,
    fontSize: 24,
    margin: '.8em 0',
  },
}));

interface PackagesCalculatorSummaryProps {
  currentWebsites: number;
  adjustedPackage: MarvelAndSnapPackage;
}

const PackagesCalculatorSummary: React.FC<PackagesCalculatorSummaryProps> = ({
  currentWebsites,
  adjustedPackage,
}) => {
  const { appBarVisible } = useContext(ThemeContext);

  const summaryStyles = useSummaryStyles(appBarVisible);

  const Checkmark: React.FC = () => (
    <img
      className={`${summaryStyles.features}__checkmark`}
      src={checkmarkIcon}
      alt="Yes"
      width="24"
      height="19"
    />
  );

  return (
    <div className={summaryStyles.root}>
      <Logo className={summaryStyles.logo} />
      <Divider flexItem={true} />
      <Typography variant="h3" className={summaryStyles.heading}>
        {adjustedPackage.name}
      </Typography>
      <Typography variant="body2" className={summaryStyles.costPerMonth}>
        Billed at{' '}
        <strong>
          {adjustedPackage.costPerMonth}/mo
          {adjustedPackage.name === 'Hosting' && '/site'}
        </strong>
      </Typography>
      {adjustedPackage.name !== 'Hosting' && currentWebsites > 1 && (
        <Typography
          variant="body2"
          className={summaryStyles.additionalCostPerMonth}
        >
          Additional sites <strong>$50/mo/site</strong>
        </Typography>
      )}
      <dl className={summaryStyles.features}>
        <dt className={`${summaryStyles.features}__label`}>Billing</dt>
        <dd className={`${summaryStyles.features}__value`}>
          {adjustedPackage.billing}
        </dd>
        {adjustedPackage.hoursPerMonth > 1 && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Monthly hours
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              {adjustedPackage.hoursPerMonth}
            </dd>
          </React.Fragment>
        )}
        {adjustedPackage.responseTime && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Response time
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              {adjustedPackage.responseTime}
            </dd>
          </React.Fragment>
        )}
        {adjustedPackage.weeklyUpdates && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Weekly updates
              <sup>
                <a href="#footnote-1">1</a>
              </sup>
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              <Checkmark />
            </dd>
          </React.Fragment>
        )}
        {adjustedPackage.uptimeMonitoring && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Uptime monitoring
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              <Checkmark />
            </dd>
          </React.Fragment>
        )}
        {adjustedPackage.weeklyAnalytics && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Weekly analytics
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              <Checkmark />
            </dd>
          </React.Fragment>
        )}
        {adjustedPackage.monthlyTimesheets && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Detailed timesheets
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              <Checkmark />
            </dd>
          </React.Fragment>
        )}
        {adjustedPackage.rollingHours && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Rolling hours
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              <Checkmark />
            </dd>
            {adjustedPackage.maximumRollingHours && (
              <React.Fragment>
                <dt className={`${summaryStyles.features}__label`}>
                  Max. rolling hours
                </dt>
                <dd className={`${summaryStyles.features}__value`}>
                  {adjustedPackage.maximumRollingHours}
                </dd>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {adjustedPackage.managedHosting && (
          <React.Fragment>
            <dt className={`${summaryStyles.features}__label`}>
              Managed hosting
              <sup>
                <a href="#footnote-2">2</a>
              </sup>
            </dt>
            <dd className={`${summaryStyles.features}__value`}>
              <Checkmark />
            </dd>
          </React.Fragment>
        )}
      </dl>
      <Divider flexItem={true} />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="small"
        disableElevation
        style={{
          minHeight: 61,
          minWidth: 'unset',
          maxWidth: 260,
          width: '100%',
        }}
      >
        Let&apos;s talk
      </Button>
    </div>
  );
};

export default PackagesCalculatorSummary;
